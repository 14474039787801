import i18Next from 'i18next';
import { initReactI18next } from 'react-i18next';

import ru from '../../../lang/ru.json';
import en from '../../../lang/en.json';
import tr from '../../../lang/tr.json';
import de from '../../../lang/de.json';
import pl from '../../../lang/pl.json';
import pt from '../../../lang/pt.json';
import th from '../../../lang/th.json';
import hi from '../../../lang/hi.json';

import dayjs from 'dayjs';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
dayjs.locale(cookies.get('lang'));

const config = {
  fallbackLng: ['ru', 'en', 'tr', 'de', 'pl', 'pt', 'th', 'hi'],
  resources: {
    en: {
      translation: en,
    },
    pt: {
      translation: pt,
    },
    ru: {
      translation: ru,
    },
    tr: {
      translation: tr,
    },
    de: {
      translation: de,
    },
    pl: {
      translation: pl,
    },
    hi: {
      translation: hi,
    },
    th: {
      translation: th,
    },
  },
  //  debug: true
};

i18Next.use(initReactI18next).init({ ...config, lng: cookies.get('lang') });

i18Next.on('languageChanged', lng => {
  dayjs.locale(lng);
});

export { i18Next, config };
